@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300;400&display=swap);
@import url(https://unpkg.com/vue-tel-input@5.12.0/dist/vue-tel-input.css);
.review-link-widget {
  font-family: 'Roboto', sans-serif;
  font-size: 1rem;
  margin: 30px 0;
  padding: 25px 20px 30px 20px;
}
.review-link-widget .vue-tel-input {
    border-radius: 8px;
    background: #fff;
    border-color: #ccc;
}
.review-link-widget .vue-tel-input input[type="tel"] {
      border: 0;
}
.review-link-widget .vue-tel-input .vti__dropdown:hover {
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
}
.review-link-widget h3 {
    font-size: 2em;
    font-weight: 400;
    margin-bottom: .5em;
    margin-top: 0;
}
.review-link-widget .place-search-row {
    margin-bottom: 10px;
}
.review-link-widget .just-center {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
}
.review-link-widget .text-center {
    text-align: center;
}
.review-link-widget .float-left {
    float: left;
}
.review-link-widget .rl-mt-4 {
    margin-top: 1.5rem;
}
.review-link-widget .center-content {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
}
.review-link-widget .row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    margin-left: -15px;
    margin-right: -15px;
}
.review-link-widget .row [class*="col"] {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      -webkit-box-flex: 1;
          -ms-flex: 1;
              flex: 1;
      padding: 5px 15px;
}
.review-link-widget .row [class*="col"].double-column {
        -webkit-box-flex: 2;
            -ms-flex: 2;
                flex: 2;
}
.review-link-widget > .row {
    margin-left: -25px;
    margin-right: -25px;
}
.review-link-widget > .row > [class*="col"] {
      padding-right: 25px;
      padding-left: 25px;
}
.review-link-widget input, .review-link-widget button, .review-link-widget .btn {
    font-size: 1.25rem;
    width: 100%;
    border-radius: 7px;
    line-height: 30px;
    border: 0;
    padding: 8px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
}
.review-link-widget input:focus-visible, .review-link-widget button:focus-visible, .review-link-widget .btn:focus-visible {
      outline: none;
}
.review-link-widget input:disabled, .review-link-widget button:disabled, .review-link-widget .btn:disabled {
      background: rgba(208, 208, 208, 0.5);
      text-align: left;
}
.review-link-widget #placesearch {
    text-align: left;
}
.review-link-widget button, .review-link-widget .btn {
    text-align: center;
    text-decoration: none;
    cursor: pointer;
}
.review-link-widget button:hover, .review-link-widget .btn:hover {
      opacity: 0.9;
}
.review-link-widget input.has-error {
    border: 1px solid red;
}
.review-link-widget input[type="text"], .review-link-widget input[type="tel"] {
    border: 1px solid #ccc;
}
.review-link-widget span.error {
    display: block;
    margin-top: 5px;
}
.review-link-widget .link-display {
    padding: 10px;
    background: #eff3f7;
    border-radius: 10px;
}
.review-link-widget .link-display .intro-image {
      width: 30px;
      display: inline-block;
}
.review-link-widget .link-display .intro-text {
      width: calc(100% - 30px);
      display: inline-block;
      padding-left: 20px;
      vertical-align: top;
      -webkit-box-sizing: border-box;
              box-sizing: border-box;
}
.review-link-widget .link-display .intro-text strong {
        font-weight: bold;
        display: block;
        font-size: 16px;
        margin: 0;
}
.review-link-widget .link-display .intro-text em {
        margin: 0;
        font-size: 13px;
}
.review-link-widget .link-display .copy-link {
      width: 100%;
      margin-top: 15px;
}
.review-link-widget .link-display .copy-link input {
        width: calc(100% - 65px);
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
}
.review-link-widget .link-display .copy-link button {
        width: 65px;
        line-height: 30px;
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
        vertical-align: top;
}
.review-link-widget .link-display .copy-link button svg {
          height: 25px;
          vertical-align: text-bottom;
}
.review-link-widget .link-display .input-group-append button {
      background: #537bba;
      color: white;
}
.review-link-widget .qr-row > hr {
    width: calc(100% - 50px);
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    border-color: #545454;
}
.review-link-widget .spinner {
    -webkit-animation-name: spin;
    animation-name: spin;
    -webkit-animation-duration: 2s;
    animation-duration: 2s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-delay: 0s;
    animation-delay: 0s;
    -webkit-animation-direction: normal;
    animation-direction: normal;
    height: 30px;
}
.review-link-widget .spinner path {
      shape-rendering: optimizeSpeed;
}
@-webkit-keyframes spin {
from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
}
to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
}
}
@keyframes spin {
from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
}
to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
}
}
@media screen and (max-width: 767px) {
iframe.map-frame {
    max-width: 100%;
    height: auto;
    margin-top: 15px;
    min-height: 265px;
}
.double-column {
    -webkit-box-flex: 1 !important;
        -ms-flex: 1 !important;
            flex: 1 !important;
}
.col-lg-6 {
    width: 100% !important;
    display: block !important;
}
.review-link-widget .row [class*="col"] {
    width: 100%;
    display: block;
    -webkit-box-flex: 0;
        -ms-flex: none;
            flex: none;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
}
.view-link, .view-map {
    width: 100%;
    display: block;
}
}




/*# sourceMappingURL=chunk-1f428134.ba50e03a.css.map*/